import { gql } from "@apollo/client";

export const GQL_INSERT_PAYMENT_LINK_TRANSACTION_PUBLIC = gql`
  mutation insertPaymentLinkTransactionPublic(
    $input: PaymentLinkTransactionPublic!
  ) {
    insertPaymentLinkTransactionPublic(input: $input) {
      errorMessage
      customerId
      paymentLinkId
      status
      paymentLinkName
      paymentLinkDescription
      amount
      id
      xenditId
      wordToRedirectLink
      redirectLink
      isInTrial
      paymentLink {
        user {
          payChannelConfig {
            config
          }
        }
      }
    }
  }
`;

export const GQL_GET_PAYMENT_LINK_PAGE = gql`
  query getPaymentLinkPageByUsername(
    $username: String!
    $page: Int
    $pageSize: Int
    $id: ID
    $link: String
    $name: String
    $type: String
    $excludeType: String
    $sourcePage: String
    $status: String
    $sortField: String
    $sortDirection: String
    $tag: TagInput
  ) {
    getPaymentLinkPageByUsername(
      username: $username
      page: $page
      pageSize: $pageSize
      id: $id
      link: $link
      name: $name
      type: $type
      excludeType: $excludeType
      sourcePage: $sourcePage
      status: $status
      sortField: $sortField
      sortDirection: $sortDirection
      tag: $tag
    ) {
      errorMessage
      items {
        user {
          paymeLink
          account {
            name
            email
            mobile
            profile
            logo {
              url
              width
              height
            }
            isVerified
          }
        }
        id
        type
        amount
        crossoutPrice
        status
        category
        coverImageId
        createdAt
        description
        startAt
        eventStartAt
        eventEndAt
        eventUrl
        expiredAt
        link
        name
        reason
        receiver
        redirectUrl
        target
        terms
        updatedAt
        usage
        userId
        subType
        urlParamTracking
        variant
        event {
          eventAddress
          mapLocation
          isOnlineEvent
        }
        coverImage {
          url
          width
          height
          id
        }
        checkoutSettings {
          buttonName
          isPopupRecentTransaction
        }
        multipleImage {
          id
          url
          width
          height
          createdAt
          updatedAt
        }
        order {
          discountAmount
          sku
          variant
          vendor
          wholesaleAmount
        }
        metadata {
          pages
          author
          ISBN
          format
          language
          publishedAt
          duration
        }
        ackeeAnalytics {
          analyticDomainId
          analyticEventId
          eventType
        }
        bundlingPaymentLinks {
          status
          variantId
          paymentLink {
            id
            name
            type
            amount
            status
            variant
            coverImage {
              url
              id
            }
            multipleImage {
              id
              url
              updatedAt
            }
          }
        }
        membershipTier {
          id
          name
          status
          membershipTierPeriod {
            id
            status
            membershipTierId
            monthPeriod
            amount
          }
        }
      }
      userDetail {
        publicAnnouncement
        account {
          name
          email
          mobile
          profile
          logo {
            url
          }
          isVerified
        }
      }
      conversionSettings {
        trackingId
        type
        conversionSettingEvents {
          name
          page
        }
      }
      total
    }
  }
`;

export const GQL_GET_EVENT_PAYMENT_LINK_PAGE = gql`
  query getPaymentLinkPageByUsername(
    $username: String!
    $page: Int
    $pageSize: Int
    $id: ID
    $link: String
    $name: String
    $type: String
    $excludeType: String
    $sourcePage: String
    $status: String
    $sortField: String
    $sortDirection: String
  ) {
    getPaymentLinkPageByUsername(
      username: $username
      page: $page
      pageSize: $pageSize
      id: $id
      link: $link
      name: $name
      type: $type
      excludeType: $excludeType
      sourcePage: $sourcePage
      status: $status
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      errorMessage
      items {
        user {
          paymeLink
          account {
            name
            email
            mobile
            profile
            logo {
              url
              width
              height
            }
            isVerified
          }
        }
        id
        type
        amount
        status
        category
        coverImageId
        createdAt
        description
        startAt
        eventStartAt
        eventEndAt
        eventUrl
        expiredAt
        link
        name
        reason
        receiver
        redirectUrl
        target
        terms
        updatedAt
        usage
        userId
        subType
        urlParamTracking
        ackeeAnalytics {
          analyticDomainId
          analyticEventId
          eventType
        }
        event {
          eventAddress
          mapLocation
          isOnlineEvent
          ticketEvent {
            id
            amount
            name
            quantity
            isVisible
            ticketStartDate
            ticketEndDate
            description
            isTemporarySoldOut
            position
          }
        }
        coverImage {
          url
          width
          height
          id
        }
        checkoutSettings {
          buttonName
          isPopupRecentTransaction
        }
        multipleImage {
          id
          url
          width
          height
          createdAt
          updatedAt
        }
        order {
          discountAmount
          sku
          variant
          vendor
          wholesaleAmount
        }
        metadata {
          pages
          author
          ISBN
          format
          language
          publishedAt
          duration
        }
      }
      userDetail {
        publicAnnouncement
        account {
          name
          email
          mobile
          profile
          logo {
            url
          }
          isVerified
        }
      }
      conversionSettings {
        trackingId
        type
        conversionSettingEvents {
          name
          page
        }
      }
      total
    }
  }
`;

export const GQL_GET_TOTAL_PAYMENT_LINK_TRANSACTION = gql`
  query getTotalPaymentLinkTransaction(
    $search: PaymentLinkTransactionSearchInput!
  ) {
    getTotalPaymentLinkTransaction(search: $search) {
      total
    }
  }
`;

export const GQL_GET_PAYMENT_LINK = gql`
  query getPaymentLinkByUsername($link: String!) {
    getPaymentLinkByUsername(link: $link) {
      id
      amount
      description
      name
      type
      status
      expiredAt
      startAt
      eventStartAt
      eventUrl
      notes
      userId
      items
      target
      tax
      errorMessage
      expiredTime
      link
      paymentType
      qurbanType
      variant
      membershipCustomerId
      writingType
      writingPaymentMethod
      writingChapters {
        id
        title
        amount
        position
      }
      ackeeAnalytics {
        analyticDomainId
        analyticEventId
        eventType
      }
      membershipInfo {
        type
      }
      membershipTier {
        id
        createdAt
        description
        status
        limit
        name
        paymentAtStart
        paymentLinkId
        isTrialAvailable
        upfrontFee
        membershipTierPeriod {
          id
          amount
          status
          monthPeriod
        }
      }
      feeRules {
        accountId
        createdAt
        fee
        feeRuleId
        type
        updatedAt
        userId
        vendor
      }
      payChannelConfig {
        config
        isAkulakuApproved
        isChannelFeeBorneByCustomer
        isAdminFeeBorneByCustomer
        isCreditCardApproved
      }
      event {
        id
        maxTicket
        ticketEvent {
          id
          amount
          name
          quantity
          isVisible
          ticketStartDate
          ticketEndDate
          description
          isTemporarySoldOut
          position
        }
      }
      order {
        discountAmount
        id
        variant
        sku
        weight
        length
        width
        height
      }
      subType
      qty
      crossSellPaymentLinks {
        id
        discountType
        discountValue
        crossSellPaymentLink {
          id
          amount
          name
          type
          link
          variant
          coverImage {
            url
          }
          multipleImage {
            url
          }
        }
      }
      user {
        paymeLink
      }
      membershipTier {
        name
        limit
        description
      }
      customer {
        id
        email
        name
        mobile
      }
      coverImage {
        url
        width
        height
      }
      form {
        fieldObject
      }
      multipleImage {
        url
        updatedAt
        height
        width
      }
      checkoutSettings {
        buttonName
        buttonNote
        discountButton
        couponOption
        paymentLinkId
        termOption
        termUrl
        isSupportGift
      }
      conversionSettings {
        id
        trackingId
        type
        status
        paymentLinkId
        conversionSettingEvents {
          id
          name
          page
        }
      }
      bundlingPaymentLinks {
        variantId
        paymentLink {
          amount
          name
          type
          variant
        }
      }
    }
  }
`;

export const GET_PAYMENT_LINK_BY_ID = gql`
  query getPaymentLinkById($id: ID!) {
    getPaymentLinkById(id: $id) {
      id
      type
    }
  }
`;

export const GQL_GET_PAYMENT_LINK_WRITING_PUBLIC = gql`
  query getPaymentLinkPageByUsername(
    $username: String!
    $page: Int
    $pageSize: Int
    $id: ID
    $link: String
    $name: String
    $type: String
    $excludeType: String
    $sourcePage: String
    $status: String
    $sortField: String
    $sortDirection: String
    $tag: TagInput
  ) {
    getPaymentLinkPageByUsername(
      username: $username
      page: $page
      pageSize: $pageSize
      id: $id
      link: $link
      name: $name
      type: $type
      excludeType: $excludeType
      sourcePage: $sourcePage
      status: $status
      sortField: $sortField
      sortDirection: $sortDirection
      tag: $tag
    ) {
      errorMessage
      items {
        user {
          paymeLink
          account {
            name
            email
            mobile
            profile
            logo {
              url
              width
              height
            }
            isVerified
          }
        }
        id
        type
        amount
        status
        category
        coverImageId
        createdAt
        description
        startAt
        expiredAt
        link
        name
        updatedAt
        usage
        userId
        paymentType
        urlParamTracking
        writingType
        writingPaymentMethod
        writingChapters {
          id
          title
          status
          position
          releasedAt
          amount
        }
        coverImage {
          url
          width
          height
          id
        }
        multipleImage {
          url
          width
          height
          updatedAt
        }
        checkoutSettings {
          buttonName
          isPopupRecentTransaction
        }
        metadata {
          author
          format
          language
          publishedAt
          duration
          genre {
            name
          }
        }
        ackeeAnalytics {
          analyticDomainId
          analyticEventId
          eventType
        }
      }
      userDetail {
        publicAnnouncement
        account {
          name
          email
          mobile
          profile
          logo {
            url
          }
          isVerified
        }
      }
      conversionSettings {
        trackingId
        type
        conversionSettingEvents {
          name
          page
        }
      }
      total
    }
  }
`;

export const GET_PAYMENT_LINK_FOR_PAYMENT = gql`
  query getPaymentLinkByUsername($link: String!) {
    getPaymentLinkByUsername(link: $link) {
      id
      amount
      description
      name
      type
      status
      expiredAt
      startAt
      eventStartAt
      eventUrl
      notes
      userId
      items
      target
      tax
      errorMessage
      expiredTime
      link
      paymentType
      qurbanType
      variant
      qty
      formId
      subType
      membershipCustomerId
      defaultMembershipTierId
      writingType
      writingPaymentMethod
      crossoutPrice
      ackeeAnalytics {
        analyticDomainId
        analyticEventId
        eventType
      }
      writingChapters {
        id
        title
        amount
        position
      }
      membershipInfo {
        type
      }
      membershipTier {
        id
        createdAt
        description
        status
        limit
        name
        paymentAtStart
        paymentLinkId
        isTrialAvailable
        upfrontFee
        membershipTierPeriod {
          id
          amount
          status
          monthPeriod
          isLifetime
        }
      }
      event {
        id
        maxTicket
        ticketEvent {
          id
          amount
          name
          quantity
          isVisible
          ticketStartDate
          ticketEndDate
          description
          isTemporarySoldOut
          position
        }
      }
      order {
        discountAmount
        id
        variant
        sku
        weight
        length
        width
        height
      }
      crossSellPaymentLinks {
        id
        discountType
        discountValue
        crossSellPaymentLink {
          id
          amount
          name
          type
          link
          variant
          coverImage {
            url
          }
          multipleImage {
            url
          }
        }
      }
      customer {
        id
        email
        name
        mobile
      }
      coverImage {
        url
        width
        height
      }
      form {
        fieldObject
      }
      multipleImage {
        url
        updatedAt
        height
        width
      }
      checkoutSettings {
        buttonName
        buttonNote
        discountButton
        couponOption
        paymentLinkId
        termOption
        termUrl
        isSupportGift
        recommendedAmount
        isPopupRecentTransaction
      }
      conversionSettings {
        id
        trackingId
        type
        status
        paymentLinkId
        conversionSettingEvents {
          id
          name
          page
        }
      }
      bundlingPaymentLinks {
        variantId
        paymentLink {
          amount
          name
          type
          variant
        }
      }
    }
  }
`;

export const GET_PAYMENT_LINK_FOR_PAYMENT_TEST_AJAH = gql`
  query getPaymentLinkByUsernameTesAjah($link: String!) {
    getPaymentLinkByUsernameTesAjah(link: $link) {
      id
      amount
      description
      name
      type
      status
      expiredAt
      startAt
      eventStartAt
      eventUrl
      notes
      userId
      items
      target
      tax
      errorMessage
      expiredTime
      link
      paymentType
      qurbanType
      variant
      qty
      formId
      subType
      membershipCustomerId
      defaultMembershipTierId
      writingType
      writingPaymentMethod
      crossoutPrice
      event {
        id
        maxTicket
        ticketEvent {
          id
          amount
          name
          quantity
          isVisible
          ticketStartDate
          ticketEndDate
          description
          isTemporarySoldOut
          position
        }
      }
      order {
        discountAmount
        id
        variant
        sku
        weight
        length
        width
        height
      }
      customer {
        id
        email
        name
        mobile
      }
      coverImage {
        url
        width
        height
      }
      form {
        fieldObject
      }
      multipleImage {
        url
        updatedAt
        height
        width
      }
      checkoutSettings {
        buttonName
        buttonNote
        discountButton
        couponOption
        paymentLinkId
        termOption
        termUrl
        isSupportGift
        recommendedAmount
        isPopupRecentTransaction
      }
    }
  }
`;

export const GQL_GET_PAYMENT_LINK_METADATA_CHECKOUT = gql`
  query getPaymentLinkFilteredList($search: PaymentLinkSearchInput!) {
    getPaymentLinkFilteredList(search: $search, limit: 1) {
      name
      user {
        paymeLink
        account {
          name
        }
      }
    }
  }
`;
