import { gql } from '@apollo/client';

export const GQL_INSERT_COHORT_ATTENDANCE = gql`
  mutation insertCohortAttendanceByEmail(
    $sessionId: ID!
    $email: String!
    $paymeLink: String!
    $paymentLinkSlug: String!
    $feedback: String!
  ) {
    insertCohortAttendanceByEmail(
      sessionId: $sessionId
      email: $email
      paymeLink: $paymeLink
      paymentLinkSlug: $paymentLinkSlug
      feedback: $feedback
    ) {
      cohortSessionId
      createdAt
      email
      id
      isAttend
      name
      paymentLinkId
      userId
      status
      message
      feedback
    }
  }
`;

export const GQL_GET_COHORT_PAGE_PUBLIC = gql`
  query getCohortPageByUsername(
    $username: String!
    $page: Int
    $pageSize: Int
    $id: ID
    $link: String
    $status: String
  ) {
    getCohortPageByUsername(
      username: $username
      page: $page
      pageSize: $pageSize
      id: $id
      link: $link
      status: $status
    ) {
      errorMessage
      items {
        user {
          bunnyLibraryId
          account {
            name
            email
            mobile
            profile
            logo {
              url
            }
          }
        }
        id
        amount
        crossoutPrice
        status
        createdAt
        startAt
        description
        link
        name
        embedUrl
        redirectUrl
        updatedAt
        expiredAt
        ackeeAnalytics {
          analyticDomainId
          analyticEventId
          eventType
        }
        coverImage {
          url
          height
          width
        }
        multipleImage {
          url
          width
          height
        }
        checkoutSettings {
          buttonName
          isPopupRecentTransaction
        }
        courseSections {
          description
          id
          name
          position
          link
          courseModules {
            id
            name
            type
            position
            link
            description
            isPreview
            isContentNotProtected
            isVisible
            coachingUrl
            embedUrl
            quiz
            content {
              files {
                id
                filename
                size
                mimetype
              }
              file {
                id
                filename
                size
                mimetype
                bunnyVideoId
              }
              id
            }
          }
        }
        cohortSessions {
          description
          id
          name
          position
          status
          speaker
          speakerProfile
          eventStartAt
          eventEndAt
        }
        landingPromotion {
          id
          testimonials
          targetAudience
          syllabus
          keyOutcome
          faqs
        }
      }
      conversionSettings {
        trackingId
        type
        conversionSettingEvents {
          name
          page
        }
      }
      total
    }
  }
`;
